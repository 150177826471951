import { AuthenticationService } from "./../_services/auth.service";
import Globalize from "globalize";
declare var require: (e) => any;
import { Component, AfterViewInit, ElementRef, OnDestroy } from "@angular/core";
import {
  DashboardControl,
  ResourceManager,
  DashboardPanelExtension,
} from "devexpress-dashboard";
import { ToastrService } from "ngx-toastr";

import AnaliticsCore from "@devexpress/analytics-core";
import {
  DashboardMenuItem,
  ToolboxExtension,
} from "devexpress-dashboard/designer";
import "devextreme/localization/globalize/number";
import "devextreme/localization/globalize/date";
import "devextreme/localization/globalize/currency";
import "devextreme/localization/globalize/message";
import DevExpress from "@devexpress/analytics-core";
import { Router } from "@angular/router";

AnaliticsCore.Analytics.Localization.loadMessages(
  require("./localization/dx-dashboard.tr.json")
);
AnaliticsCore.Analytics.Localization.loadMessages(
  require("./localization/dx-analytics-core.tr.json")
);
AnaliticsCore.Analytics.Localization.loadMessages(
  require("./localization/dx-reporting.tr.json")
);

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements AfterViewInit, OnDestroy {
  private dashboardControl: DashboardControl;

  constructor(
    private element: ElementRef,
    private auth: AuthenticationService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.initGlobalize();
    ResourceManager.embedBundledResources();
  }

  initGlobalize() {
    Globalize.load(require("devextreme-cldr-data/en.json"));
    Globalize.load(require("devextreme-cldr-data/tr.json"));
    Globalize.load(require("devextreme-cldr-data/supplemental.json"));
    Globalize.locale("tr");
  }

  logout() {
    this.auth.logout();
  }

  ngAfterViewInit(): void {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const that = this;

    this.dashboardControl = new DashboardControl(
      this.element.nativeElement.querySelector(".dashboard-container"),
      {
        endpoint: currentUser.apiUrl + "dashboard",
        workingMode: currentUser.authorityLevel === 1 ? "Viewer" : "ViewerOnly",
        extensions: {
          "data-source-wizard": {
            enableCustomSql: true,
          },
          "dashboard-export": {
            allowExportDashboard: true,
            allowExportDashboardItems: false,
            pdfExportOptions: {},
            imageExportOptions: {},
            excelExportOptions: {},
          },
        },
        onDashboardBeginUpdate: () => {
          // if (!that.auth.controlIdleTime()){
          //   this.router.navigate(["/login"]);
          // }
        },
        ajaxRemoteService: {
          headers:{
            "Authorization": that.auth.getAuthToken(),
            "credentials": "omit",
            "Referrer-Policy": "no-referrer-when-downgrade"
          },
          complete: function (jqXHR, textStatus) {
            console.log(jqXHR);
            if (jqXHR.statusText === "error" && jqXHR.status === 0) {
              that.toastr.error("Veritabanı ile bağlantı kurulamadı !");
            }

            // if (!that.auth.controlIdleTime()) {
            //   that.auth.logout();
            //   window.location.reload();
            // }

            // if (jqXHR.responseText != null && (jqXHR.responseText.indexOf('refresh') >=0 || jqXHR.responseText.toLocaleLowerCase().indexOf('internal server error') >=0)) {
            //   that.auth.refreshToken().subscribe(x => {
            //     that.dashboardControl.requestDashboardList();
            //     that.dashboardControl.reloadData();
            //   });
            // }
          },
        },
      }
    );
    this.dashboardControl.registerExtension(
      new DashboardPanelExtension(this.dashboardControl, {
        dashboardThumbnail: "../../assets/SalesOverview.png",
      })
    );
    this.dashboardControl.render();
  }
  ngOnDestroy(): void {
    this.dashboardControl && this.dashboardControl.dispose();
  }
}
