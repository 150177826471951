import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { ICurrentUser } from "../_models/currentUser";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient, private router: Router) {}

  login(email: string, password: string): Observable<ICurrentUser> {
    localStorage.setItem("idletime", JSON.stringify(new Date().getTime()));

    return this.http
      .post<ICurrentUser>(environment.adminApiUrl + "user/Authenticate", {
        UserName: email,
        Password: password,
      })
      .pipe(
        map((user) => {
          if (user && user.accessToken) {
            localStorage.setItem("currentUser", JSON.stringify(user));
          }
          return <ICurrentUser>user;
        })
      );
  }

  logout() {
    localStorage.removeItem("currentUser");
    this.router.navigate(["/login"]);
  }

  refreshToken(): Observable<ICurrentUser> {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const atoken = currentUser.accessToken;
    const rtoken = currentUser.refreshToken;
    return this.http
      .post<ICurrentUser>(environment.adminApiUrl + "user/refresh", {
        token: atoken,
        refreshToken: rtoken,
      })
      .pipe(
        map((user) => {
          if (user && user.accessToken) {
            localStorage.setItem("currentUser", JSON.stringify(user));
          }
          return <ICurrentUser>user;
        }),
        catchError(this.handleError)
      );
  }

  controlIdleTime() {
    var idletime = JSON.parse(localStorage.getItem("idletime"));
    if (idletime == null) return false;
    else {
      var simdi = new Date().getTime();
      var fark = simdi - idletime;

      if (fark > 60000) return false;
      else
        localStorage.setItem("idletime", JSON.stringify(new Date().getTime()));
      return true;
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }

  getAuthToken(): string {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser != null) {
      return currentUser.accessToken;
    }
    return "";
  }
}
